import './App.css';
import AboutMe from './components/aboutMe/aboutMe';
import Footer from './components/footer/footer';
import Navbar from './components/header/navbar';
import NavbarMobile from './components/header/navbarMobile';
import ImageCards from './components/imageCards/imageCards';

function App() {
  return (
    <div className="app">
      <Navbar />
      <NavbarMobile />
      <ImageCards />
      <AboutMe />
      <Footer />
    </div>
  );
}

export default App;
