// src/Navbar.js
import React, { useState } from 'react';
import './navbarMobile.css'

const NavbarMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar-mobile">
      <div className="brand-name">
        <h1>Moonnila</h1>
        <p>Beauty & Nails</p>
      </div>
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
      <div className={`menu ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#about">Beauty</a></li>
          <li><a href="#services">Nails</a></li>
          <li><a href="#contact">About Me</a></li>
        </ul>
      </div>
    </nav>
  );
};

export default NavbarMobile;
