import React from 'react';
import './navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar">
            <ul className="nav-list">
                <li className="nav-item">HOME</li>
                <li className="nav-item">BEAUTY</li>
                <li className="nav-item nav-center">
                    <div className="brand-name">
                        <h1>Moonnila</h1>
                        <p>Beauty & Nails</p>
                    </div>
                </li>
                <li className="nav-item">NAILS</li>
                <li className="nav-item">ÜBER MICH</li>
            </ul>
        </nav>
    );
}

export default Navbar