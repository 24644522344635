import React from 'react';
import beauty from './../../assets/img/Beauty.png';
import nails from './../../assets/img/Nails.png';
import './imageCards.css';

const ImageCards = () => {
    return (
        <div className='image-container'>
            <div className='image-wrapper'>
                <img className='moonilaBeauty' src={beauty} alt="Moonila Beauty" />
                <button className='image-button'>Moonnila Beauty</button>
            </div>
            <div className='image-wrapper'>
                <img className='moonilaNails' src={nails} alt="Moonila Nails" />
                <button className='image-button'>Moonnila Nails</button>
            </div>
        </div>
    );
}

export default ImageCards;
