import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-left'>
        <p className='logo'>Moonnila</p>
      </div>
      <div className='footer-center'>
        <p>&copy; <a href='polyapps.ch' class='polyApps'>
          {new Date().getFullYear()} polyApps</a>
        </p>
      </div>
      <div className='footer-right'>
        <a href='#' className='footer-link'>Datenschutz</a>
        <span> | </span>
        <a href='#' className='footer-link'>Impressum</a>
      </div>
    </footer>
  );
};

export default Footer;
